import PropTypes from 'prop-types'
import styles from '@/styles/header/DropDownMenu.module.js'
import { Map } from 'immutable'
import React, { Component } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Divider from '@mui/material/Divider'
import GAEventTracker from '@/libs/GAEventTracker'

const SUPPORT_EMAIL = 'support@tsunamiworks.com'
class DropDownMenu extends Component {
  constructor(props) {
    super(props)
    this._closeMenu = this._closeMenu.bind(this)
    this._handleClick = this._handleClick.bind(this)
    this._handleMenuClick = this._handleMenuClick.bind(this)
    this.state = {
      isOpen: false,
      anchorEl: null,
    }
  }
  render() {
    const { anchorEl } = this.state
    const { setLoginModalOpenStatus, currentUser } = this.props
    return (
      <div>
        <style jsx>{styles}</style>
        <IconButton
          id='menu-button'
          style={{ color: 'white' }}
          aria-controls={anchorEl ? 'drop-menu' : null}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : null}
          onClick={this._handleClick}>
          <MenuIcon />
        </IconButton>
        <Menu
          id="drop-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          MenuListProps={{
            'aria-labelledby': 'menu-button',
          }}
          onClose={this._closeMenu}>
          <MenuItem
            component="a"
            href="/ranking/monthly/"
            onClick={this._handleMenuClick('月排行')}>
            月排行
          </MenuItem>
          <MenuItem
            component="a"
            href="/ranking/weekly/"
            onClick={this._handleMenuClick('週排行')}>
            周排行
          </MenuItem>
          <MenuItem
            component="a"
            href="/"
            onClick={this._handleMenuClick('搜尋餐廳')}>
            搜尋餐廳
          </MenuItem>
          <Divider />
          <MenuItem
            component="a"
            href="/reservation/taipei"
            onClick={this._handleMenuClick('線上訂位')}>
            線上訂位
          </MenuItem>
          <MenuItem
            component="a"
            href="/c/list/all"
            onClick={this._handleMenuClick('美食專欄')}>
            美食專欄
          </MenuItem>
          <MenuItem
            component="a"
            href="/blogger"
            onClick={this._handleMenuClick('發表食記')}>
            發表食記
          </MenuItem>
          <MenuItem
            className="app-download-btn"
            onClick={this._handleMenuClick('下載App')}>
            <a href="/app" className="header-app-link">
              下載APP
            </a>
          </MenuItem>
          <Divider />
          {this._renderBusinessMenu()}
          <Divider />
          {this._isLoggedin() ? (
            <MenuItem component="a" href="/logout">
              登出
            </MenuItem>
          ) : (
            <MenuItem
              component="a"
              onClick={() => {
                setLoginModalOpenStatus({ isOpen: true })
                this._closeMenu()
              }}>
              登入
            </MenuItem>
          )}
        </Menu>
      </div>
    )
  }
  _renderBusinessMenu() {
    const { currentUser } = this.props
    let inviteBloggerLink = '/why-ifoodie#feature-highlights'
    if (currentUser.get('isBusiness')) {
      inviteBloggerLink = '/business/case'
    }
    return (
      <>
      <style jsx>{styles}</style>
      <MenuItem
        component="a"
        href="/why-ifoodie"
        onClick={this._handleMenuClick('行銷方案')}>
        行銷方案
      </MenuItem>
      <MenuItem
        component="a"
        href={inviteBloggerLink}
        onClick={this._handleMenuClick('邀請部落客')}>
        邀請部落客
      </MenuItem>
      <MenuItem
        component="a"
        href="/why-ifoodie"
        onClick={this._handleMenuClick('店家加入')}>
        店家加入
      </MenuItem>
      <MenuItem
        component="a"
        href={`mailto:${SUPPORT_EMAIL}`}
        onClick={this._handleMenuClick('聯絡我們')}>
        聯絡我們
      </MenuItem>
      </>
    )
  }
  _isLoggedin = () => {
    const { currentUser } = this.props
    return !!currentUser.get('id')
  }
  _getProfileImageUrl = () => {
    const { currentUser } = this.props
    return currentUser.get('thumb')
  }
  _handleClick(e) {
    const target = e.currentTarget

    setTimeout(() => {
      this.setState({ anchorEl: target })
      // this._sendMenuOpenEvent()
    }, 50)
    GAEventTracker.sendEvent({
      category: '點擊Header Mobile',
      action: '打開Menu',
    })
  }
  _closeMenu() {
    this.setState({ anchorEl: null })
  }
  _handleMenuClick(label) {
    return () => {
      GAEventTracker.sendEvent({
        category: '點擊Header Mobile',
        action: '點擊Menu內容',
        label,
      })
      this._closeMenu()
    }
  }
}

DropDownMenu.propTypes = {
  setLoginModalOpenStatus: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map),
}

export default DropDownMenu
