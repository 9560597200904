import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search'

import ABTesting from '@/libs/ABTesting'
import { Router } from '@/routes'
import GAEventTracker from '@/libs/GAEventTracker'
import getOpenLink from '@/libs/getOpenLink'
import styles from '@/styles/BottomTabbar.module.js'
import DownloadModalBottom from './DownloadModalBottom';
import { localStorage } from '@/libs/globalUtils'

const PITCH_DOWNLOAD_APP = 'PITCH_DOWNLOAD_APP_v2'

const TabBar = Object.freeze({
  TABBAR_BLOG: 0,
  TABBAR_HOME: 1,
  TABBAR_NEARBY: 2,
  TABBAR_ARTICLE: 3,
  // TABBAR_FAVORITE: 3,
  TABBAR_APP: 4,
  // TABBAR_ACCOUNT: 4,
})

const TabBarLabel = Object.freeze({
  [TabBar.TABBAR_BLOG]: '首頁(食記)',
  [TabBar.TABBAR_HOME]: '探索餐種',
  [TabBar.TABBAR_NEARBY]: '搜尋餐廳',
  [TabBar.TABBAR_ARTICLE]: '專欄',
  // [TabBar.TABBAR_FAVORITE]: '收藏',
  [TabBar.TABBAR_APP]: 'App',
  // [TabBar.TABBAR_ACCOUNT]: '帳號',
})

function SimpleLink({ link, children }) {
  return (
    <a href={link} style={{color: 'inherit'}}  onClick={(e) => {
      e.preventDefault()
      Router.pushRoute(link)
    }}>
      {children}
    </a>
  )
}


function BottomTabbar({ isMobileOs, city = '台北市', initTab = TabBar.TABBAR_HOME }) {
  const [value, setValue] = useState(initTab);
  const [showAppDownload, setShowAppDownload] = useState(false)
  const newsLink = `/news/${city}`
  const articleLink = `/c/list/all`
  const searchLink = `/explore/${city}/list`
  const abTesting = ABTesting.getABTesting({ exp: ABTesting.EXP_EXPLORE })

  const markPitchDownloadApp = () => {
    const currentTs = new Date().getTime()
    localStorage.setItem(PITCH_DOWNLOAD_APP, currentTs)
  }

  const shouldShowPitch = () => {
    // if (abTesting == ABTesting.TESTING) {
    //   return false
    // }
    if (!localStorage.hasOwnProperty(PITCH_DOWNLOAD_APP)) {
      return true
    }
    try {
      const lastPitchTs = JSON.parse(localStorage.getItem(PITCH_DOWNLOAD_APP))
      const currentTs = new Date().getTime()
      // Do not repeatly pitch (limit: 1 days)
      const shouldPitchDownloadApp =
        currentTs - lastPitchTs > 1 * 24 * 60 * 60 * 1000
      return shouldPitchDownloadApp
    } catch (err) {
      return true
    }
  }

  useEffect(() => {
    const handleBackToPersistedPage = (event) => {
      const label = TabBarLabel[value]
      if (shouldShowPitch()) {
        setShowAppDownload(true)
        markPitchDownloadApp()
        GAEventTracker.sendEvent({
          action: '顯示pitch',
          category: label,
        })
      } else {
        GAEventTracker.sendEvent({
          action: '跳過pitch',
          category: label,
          label: '時間未到',
        })
      }
    };

    window.addEventListener('backToPersistedPage', handleBackToPersistedPage);

    return () => {
      window.removeEventListener('backToPersistedPage', handleBackToPersistedPage);
    };
  }, [])

  const _onClickDownloadApp = function(e) {
    const openLink = getOpenLink()
    e.preventDefault()
    console.log('openLink', openLink)
    window.location = openLink
    setTimeout(function () {
      window.location = 'https://ifoodie.tw/app'
    }, 1000)
    GAEventTracker.sendEvent({
      category: '點擊下載APP按鈕',
      action: '點擊下載APP按鈕',
      label: `從 Bottom Bar`,
    })
  }

  return (
    <Paper
      sx={{ width: '100%', position:'fixed', left: 0, right: 0, bottom: 0, zIndex: 1000 }} elevation={5} >
      <style jsx>{styles}</style>
      <BottomNavigation
        id="bottom-tabbar"
        showLabels
        value={value}
        onChange={(event, newValue) => {
          if (newValue == TabBar.TABBAR_APP) {
            // Do nothing
          } else {
            setValue(newValue);
          }
          const label = TabBarLabel[newValue]
          const originLabel = TabBarLabel[value]
          const actionPattern = value == newValue ? '重複點擊' : '切換'
          GAEventTracker.sendEvent({
            action: '點擊 Bottom Tabbar',
            category: `${actionPattern}`,
            label: `${originLabel} -> ${label}`
          })
        }}
      >
        <BottomNavigationAction label={<SimpleLink link={newsLink}>首頁</SimpleLink>} icon={<SimpleLink link={newsLink}><HomeIcon /></SimpleLink>} />
        <BottomNavigationAction label={<SimpleLink link={'/'}>探索餐種</SimpleLink>} icon={<SimpleLink link={'/'}><LocationOnIcon /></SimpleLink>} />
        <BottomNavigationAction label={<SimpleLink link={searchLink}>搜尋餐廳</SimpleLink>} icon={<SimpleLink link={searchLink}><SearchIcon /></SimpleLink>} />
        <BottomNavigationAction label={<SimpleLink link={articleLink}>專欄</SimpleLink>} icon={<SimpleLink link={articleLink}><ChromeReaderModeIcon /></SimpleLink>} />
        <BottomNavigationAction label={<a href='/app' onClick={_onClickDownloadApp} style={{color: 'inherit'}}>開啟 App</a>} icon={<a href='/app' onClick={_onClickDownloadApp} style={{color: 'inherit'}}><ExitToAppIcon /></a>} />
        {/* <BottomNavigationAction label="收藏" icon={<BookmarkAddedIcon />} />
        <BottomNavigationAction label="帳號" icon={<AccountCircleIcon />} /> */}
      </BottomNavigation>
      {
        showAppDownload && (
          <DownloadModalBottom onRequestClose={() => { setShowAppDownload(false) }} />
        )
      }
    </Paper>
  );
}

BottomTabbar.propTypes = {}

export default BottomTabbar
export { TabBar }